<template>
    <div class="edit-view">
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="80px"
                    label-position="right"
                    style="max-width: 800px"
                >
                    <el-form-item prop="url" label="URL">
                        <el-autocomplete
                            style="width: 100%"
                            v-model="formData.url"
                            :fetch-suggestions="querySearch"
                            @select="handleSelect"
                            placeholder="输入或选择"
                            :clearable="true"
                        >
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item prop="type" label="方法">
                        <el-select v-model="formData.method">
                            <el-option label="GET" value="get"></el-option>
                            <el-option label="POST" value="post"></el-option>
                        </el-select>
                    </el-form-item>
                    <!--<el-form-item prop="data" label="参数">-->
                    <!--<el-row>-->
                    <!--<el-col :span="6">-->
                    <!--<el-input @change="paramChange($event, 0)" placeholder="key" clearable></el-input>-->
                    <!--</el-col>-->
                    <!--<el-col :span="1" style="text-align: center">:</el-col>-->
                    <!--<el-col :span="6">-->
                    <!--<el-input placeholder="value" clearable></el-input>-->
                    <!--</el-col>-->
                    <!--</el-row>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item prop="data" v-if="i !== 0" v-for="(item, i) in params" :key="i">-->
                    <!--<el-row>-->
                    <!--<el-col :span="6">-->
                    <!--<el-input @change="paramChange($event, i)" placeholder="key" clearable></el-input>-->
                    <!--</el-col>-->
                    <!--<el-col :span="1" style="text-align: center">:</el-col>-->
                    <!--<el-col :span="6">-->
                    <!--<el-input @change="paramChange($event, i)" placeholder="value" clearable></el-input>-->
                    <!--</el-col>-->
                    <!--<el-col :span="1">-->
                    <!--<el-button type="text" icon="el-icon-circle-close" style="text-align: center;"></el-button>-->
                    <!--</el-col>-->
                    <!--</el-row>-->
                    <!--</el-form-item>-->
                    <el-form-item prop="data" label="参数">
                        <el-table :data="params" :show-header="false" border>
                            <el-table-column label="key" prop="key">
                                <template v-slot="{ row, column, $index }">
                                    <el-input
                                        v-model="row.key"
                                        @change="paramChange($event, $index)"
                                        placeholder="key"
                                        clearable
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="value" prop="value">
                                <template v-slot="{ row }">
                                    <el-input v-model="row.value" placeholder="value" clearable> </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column width="60" align="center">
                                <template v-slot="{ row, column, $index }">
                                    <el-button @click="removeParam($index)" type="text"> 删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="sendRequest" :loading="loading" type="primary">发送</el-button>
                    </el-form-item>
                    <el-form-item v-if="showResult" label="Status">
                        <el-tag :type="statusType">{{ statusCode }}</el-tag>
                    </el-form-item>
                    <el-form-item v-if="showResult" label="Response">
                        <el-tabs v-model="activeName" type="border-card" style="min-height: 150px">
                            <el-tab-pane label="JSON" name="json">
                                <json-view :json="jsonResult"></json-view>
                            </el-tab-pane>
                            <el-tab-pane label="HTML" name="html">
                                <div v-html="htmlResult"></div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    created() {
        this.axios = axios.create({
            baseURL: this.$baseUrl
        });
        this.$http.get('/dev/mappings').then(res => {
            this.urls = res.sort((a, b) => {
                return a.className.localeCompare(b.className);
            });
        });
    },
    data() {
        return {
            urls: [],
            formData: {
                url: '',
                method: 'get'
            },
            params: [
                {
                    key: '',
                    value: ''
                }
            ],
            rules: {
                url: [{ required: true, message: '请填写URL' }]
            },
            jsonResult: {},
            htmlResult: '',
            statusCode: '',
            statusType: '',
            showResult: false,
            activeName: 'json',
            loading: false,
            axios: {}
        };
    },
    methods: {
        querySearch(k, cb) {
            var arr = [];
            this.urls.forEach(i => {
                if (i.url.toLowerCase().indexOf(k.toLowerCase()) > -1) {
                    arr.push(i);
                }
            });
            cb(
                arr.map(i => {
                    return {
                        value: i.url,
                        method: i.type
                    };
                })
            );
        },
        handleSelect(item) {
            this.formData.method = item.method.toLowerCase();
        },
        paramChange(e, i) {
            if (e && this.params.length - 1 === i) {
                this.params.push({
                    key: '',
                    value: ''
                });
            }
        },
        removeParam(i) {
            if (i === 0) {
                this.$set(this.params, 0, {
                    key: '',
                    value: ''
                });
            } else {
                this.params.splice(i, 1);
            }
        },
        sendRequest() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.showResult = false;
                    this.jsonResult = {};
                    this.htmlResult = '';
                    let req = {
                        method: this.formData.method,
                        url: this.formData.url,
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    };
                    if (this.formData.method === 'get') {
                        req.params = {};
                        this.params.forEach(i => {
                            if (i.key) {
                                req.params[i.key] = i.value;
                            }
                        });
                    } else {
                        let data = new FormData();
                        this.params.forEach(i => {
                            if (i.key) {
                                data.append(i.key, i.value);
                            }
                        });
                        req.data = data;
                    }
                    this.axios(req)
                        .then(res => {
                            this.loading = false;
                            this.showResult = true;
                            this.statusCode = res.status + ' ' + res.statusText;
                            if (res.status === 200) {
                                this.statusType = 'success';
                                if (
                                    res.headers['content-type'] &&
                                    res.headers['content-type'].toLowerCase().indexOf('json') !== -1
                                ) {
                                    this.activeName = 'json';
                                    this.jsonResult = res.data;
                                    this.htmlResult = res.data;
                                } else {
                                    this.activeName = 'html';
                                    this.htmlResult = res.data;
                                }
                            } else {
                                this.statusType = 'danger';
                                this.activeName = 'html';
                            }
                        })
                        .catch(error => {
                            console.log(error.response);
                            this.loading = false;
                            this.showResult = true;
                            this.statusType = 'danger';
                            this.activeName = 'html';
                            if (error.response) {
                                this.htmlResult = error.response.data;
                                this.statusCode = error.response.status + ' ' + error.response.statusText;
                                if (
                                    error.response.headers['content-type'] &&
                                    error.response.headers['content-type'].toLowerCase().indexOf('json') !== -1
                                ) {
                                    this.activeName = 'json';
                                    this.jsonResult = error.response.data;
                                }
                            } else {
                                this.htmlResult = error;
                                this.statusCode = 'ERROR';
                            }
                        });
                }
            });
        }
    },
};
</script>

<style scoped></style>
